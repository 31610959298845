body.realisations {
    section.section-01-realisation {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        position: relative;
        margin-top: -20vw;
        transition: .5s;
        @media screen and (max-width: $size-max-m-2) {
            margin-top: 88vw;
            transition: .5s;
            @media screen and (max-width: $size-max-m-3) {
                margin-top: 100vw;
                transition: .5s;
                @media screen and (max-width: $size-max-m-5) {
                    margin-top: 108vw;
                    transition: .5s;
                }
            }
        }
        div.container-fluid.row {
            width: 90%;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            transition: .5s;
            margin: 0;
            div.menu-sect-01 {
                width: 100%;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: url(../images/bg_menu_full_left.jpg) no-repeat center center;
                background-size: cover;
                border-radius: 15px;
                transition: .5s;
                -webkit-box-shadow: -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
                -moz-box-shadow:    -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
                box-shadow:         -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
                @media screen and (max-width: $size-max-2) {
                    height: 85px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        height: 75px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            min-height: 75px;
                            transition: .5s;
                            flex-wrap: wrap;
                            padding: 15px;
                            @media screen and (max-width: $size-max-m-4) {
                                align-items: flex-start;
                                flex-direction: column;
                                height: auto;
                                padding: 15px;
                            }
                        }
                    }
                }
                a.item {
                    width: 12%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-transform: uppercase;
                    text-align: center;
                    color: $color-3;
                    font-size: 1.2vw;
                    font-weight: 500;
                    transition: .5s;
                    &.active {
                        color: $color-grey-dark;
                    }
                    @media screen and (max-width: $size-max-2) {
                        font-size: 1.2vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            width: 15%;
                            font-size: 1.8vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-2) {
                                width: 22%;
                                font-size: 14px;
                                transition: .5s;
                                margin-bottom: 5px;
                            }
                        }
                    }
                    span {
                        width: 100%;
                        text-align: center;
                        //transition: .5s;
                        @media screen and (max-width: $size-max-m-4) {
                            text-align: left;
                            //transition: .5s;
                        }
                    }
                    &:hover {
                        color: $color-grey-dark;
                        transition: .5s;
                    }
                }
                a.bar {
                    margin: 0 7px;
                    color: $color-3;
                    font-size: 1.2vw;
                }
            }

            div.content-realisations {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 45px;
                transition: .5s;
                div.content-box {
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    background: $color-white;
                    -webkit-box-shadow: -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
                    -moz-box-shadow:    -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
                    box-shadow:         -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
                    border-radius: 15px;
                    margin-bottom: 65px;
                    padding: 35px 0;
                    flex-wrap: wrap;
                    transition: .5s;
                    @media screen and (max-width: $size-max-2) {
                        padding-top: 0;
                        transition: .5s;
                    }
                    div.unique-realisation {
                        width: 30%;
                        transition: .5s;
                        margin: 15px;
                        @media screen and (max-width: $size-max-4) {
                            width: 28%;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-2) {
                                width: 42%;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-4) {
                                    width: 92%;
                                    transition: .5s;
                                    margin: 0;
                                }
                            }
                        }
                        div.header-realisations {
                            min-height: 75px;
                            display: flex;
                            align-items: flex-start;
                            justify-content: flex-end;
                            flex-direction: column;
                            padding-bottom: 5px;
                            h3 {
                                width: 100%;
                                font-weight: 500;
                                font-size: 1.0vw;
                                text-align: left;
                                @media screen and (max-width: $size-max-2) {
                                    font-size: 18px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-3) {
                                        font-size: 16px;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-4) {
                                            font-size: 14px;
                                            transition: .5s;
                                        }
                                    }
                                }
                            }
                        }
                        div.content-cover-realisations {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 1px solid $color-3;
                            border-radius: 15px;
                            overflow: hidden;
                            transition: .5s;
                        }
                        div.footer-realisations {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            padding-top: 15px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-4) {
                                padding-top: 8px;
                                transition: .5s;
                            }
                            p {
                                width: 100%;
                                font-size: 18px;
                                font-weight: 400;
                                line-height: 25px;
                                @media screen and (max-width: $size-max-2) {
                                    font-size: 16px;
                                    line-height: 20px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-4) {
                                        font-size: 14px;
                                        line-height: 18px;
                                        transition: .5s;
                                    }
                                }
                                p {
                                    width: 100%;
                                    font-size: 18px;
                                    font-weight: 400;
                                    line-height: 25px;
                                    @media screen and (max-width: $size-max-2) {
                                        font-size: 16px;
                                        line-height: 20px;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-4) {
                                            font-size: 14px;
                                            line-height: 18px;
                                            transition: .5s;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.image-link {
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}
  
/* aligns caption to center */
.mfp-title {
    text-align: center;
    padding: 6px 0;
}