section.slides.customised-slide {
    width: 100%;
    height: 55vw;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 450px;
    overflow: hidden;
    transition: .5s;    // height: 35vw;
    padding: 0;
    margin: 0;
    @media screen and (max-width: $size-max-2) {
        height: 55vw;
        max-height: 100%;
        transition: .5s;
        @media screen and (max-width: $size-max-4) {
            height: 63vw;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                height: 75vw;
                transition: .5s;
                @media screen and (max-width: $size-max-m-4) {
                    height: 110vw;
                    transition: .5s;
                }
            }
        }
    }
    div.slideshow-wrap { 
        width: 100%;
        height: 55vw;
        max-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-top: -10vw;
        transition: .5s;
        @media screen and (max-width: $size-max-1) {
            margin-top: -8vw;
            transition: .5s;
            @media screen and (max-width: $size-max-2) {
                height: 50vw;
                max-height: 100%;
                margin-top: 0;
                transition: .5s;
                @media screen and (max-width: $size-max-4) {
                    height: 100%;
                    transition: .5s;
                }
            }
        }
             
        img {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            pointer-events: none;
        }
        /*
        a:not(#lukeed) {
            color: #56aee7;
            transition: color 0.3s;
        }
        a:not(#lukeed):hover {
            color: #f6ad20;
        }
        */
        h1 {
            font-size: 4.5em;
            color: #363218;
            font-family: Ubuntu;
            font-weight: 500;
        }
        h2 {
            font-weight: 400;
            font-size: 2.3em;
            color: #363218;
        }
        p.demo-text {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            color: rgba(40, 44, 42, 0.1);
            font-weight: 900;
            font-size: 12em;
            line-height: 1;
            text-align: center;
            vertical-align: middle;
            padding: 0.8em 0;
            margin: 0;
        }
        .bee3D--parent {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
        }
        .homepage {
            position: relative;
            text-align: center;
            font-family: Oxygen;
            height: 100vh;
            header {
                margin: 0px auto 50px;
                padding-top: 30px;
                img {
                    margin-bottom: -20px;
                }
                h4 {
                    font-size: 1.4em;
                }
                a {
                    padding: 0 12.5px;
                }
            }
        }
        .examples {
            max-width: 250px;
            margin: 0 auto;
            text-align: left;
            h3 {
                font-size: 1.8em;
                text-align: center;
                margin-bottom: 1em;
                text-decoration: underline;
            }
            ul {
                font-weight: 600;
                margin: 20px auto;
                li {
                    padding-bottom: 10px;
                }
                a {
                    font-size: 1.6em;
                }
                dt {
                    font-size: 1.6em;
                    color: #363218;
                    margin-bottom: -15px;
                }
                ul {
                    margin-left: 30px;
                    li {
                        padding-bottom: 6px;
                    }
                }
            }
        }
        #lukeed {
            height: 0;
            font-size: 0;
            position: fixed;
            right: 60px;
            bottom: 80px;
            span {
                display: block;
                background: transparent url("/img/lukeed.png") no-repeat;
                background-size: 100%;
                width: 50px;
                height: 40px;
                opacity: 0.4;
                margin: 0 auto;
                transition: opacity 0.3s ease-in-out;
            }
            &:hover {
                span {
                    opacity: 1;
                }
            }
        }
        .intro-text {
            padding: 60px 10px 40px;
            text-align: center;
            h1 {
                font-size: 3em;
            }
            h3 {
                max-width: 650px;
                margin: 0 auto;
            }
        }
        .bee3D--effect__classic {
            background: rgba(243, 111, 86, 0.2);
            .bee3D--inner {
                background: #f36f56;
            }
            a {
                color: #f36f56;
            }
        }
        .bee3D--effect__carousel {
            background: rgba(121, 255, 0, 0.2);
            .bee3D--inner {
                background: #83c745;
            }
            a {
                color: #83c745;
            }
        }
        .bee3D--effect__concave {
            background: rgba(235, 156, 184, 0.3);
            background: url(../images/bg_ciel.jpg) no-repeat center center;
            background-size: cover;
            @media screen and (max-width: $size-max-4) {
                margin-top: 5vw;
                transition: .5s;
            }
            .bee3D--inner {
                background:transparent;                
                transition: .5s;
                img {
                    border-radius: 15px;
                    border: 1px solid $color-white;
                }
            }
            a {
                color: #eb9cb8;
            }
        }
        .bee3D--effect__coverflow {
            background: rgba(42, 206, 185, 0.2);
            .bee3D--inner {
                background: #2aceb9;
            }
            a {
                color: #2aceb9;
            }
        }
        .bee3D--effect__cube {
            background: rgba(86, 174, 231, 0.3);
            .bee3D--inner {
                background: #56aee7;
            }
            a {
                color: #56aee7;
            }
        }
        .bee3D--effect__arc {
            background: rgba(233, 213, 88, 0.2);
            .bee3D--inner {
                background: #ffab07;
            }
            a {
                color: #ffab07;
            }
        }
        .bee3D--effect__wave {
            background: rgba(179, 224, 153, 0.2);
            .bee3D--inner {
                background: #028f76;
            }
            a {
                color: #028f76;
            }
        }
        .bee3D--effect__spiral-right {
            background: rgba(255, 234, 173, 0.2);
            .bee3D--inner {
                background: #b3e099;
            }
            a {
                color: #b3e099;
            }
        }
        .bee3D--effect__spiral-left {
            background: rgba(255, 234, 173, 0.2);
            .bee3D--inner {
                background: #b3e099;
            }
            a {
                color: #b3e099;
            }
        }
        @media only screen and (max-width: 768px) {
            #lukeed {
                position: relative;
                bottom: auto;
                right: auto;
            }
        }    
    }

    .bee3D--effect__concave .bee3D--before,
    .bee3D--effect__concave .bee3D--after {
        opacity: 1!important;
    }

    .bee3D--nav.bee3D--nav__prev,
    .bee3D--nav.bee3D--nav__next {       
        width: 35px;
        height: 35px;       
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color-3;
        border-radius: 50%;
        text-shadow: 2px 2px 5px rgba(150, 150, 150, 1);
        z-index: 99;
        i {
            text-shadow: 1px 1px 2px rgb(104, 104, 104);
            color: $color-white;
            font-size: 2.2vw;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                font-size: 18px;
                transition: .5s;
            }
        }
        &:hover {
            background: $color-7;
            transition: .5s;
        }
    }

    .bee3D--nav.bee3D--nav__prev {
        margin-left: -1.5vw;
        transform: rotate(-90deg); /* Equal to rotateZ(90deg) */
        transition: .5s;
        i {
            margin-top: -8px;
        }
    }

    .bee3D--nav.bee3D--nav__next {
        margin-right: -2vw;
        transform: rotate(90deg); /* Equal to rotateZ(-90deg) */
        transition: .5s;
        i {
            margin-top: -8px;
        }
    }

    div.content-bg-02 {
        transition: .5s;
        position: absolute;
        bottom: -4vw;
        width: 100%;
        @media screen and (max-width: $size-max-1) {
            bottom: -2vw;
            transition: .5s;
            @media screen and (max-width: $size-max-3) {
                bottom: 0;
                transition: .5s;
            }
        }
        img {
            width: 100%;
            transition: .5s;
        }
    }

    div.content-central-items {        
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-end;
        position: absolute;
        transition: .5s;
        z-index: 5;
        div.top-slide-caption {
            width: 100%;
            height: 15vw;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            padding-left: 8vw;
            margin-top: 18vw;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                margin-top: 10vw;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    margin-top: 15vw;
                    padding-left: 10vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-4) {
                        margin-top: 22vw;
                        padding-left: 18vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-5) {
                            margin-top: 35vw;
                            transition: .5s;
                        }
                    }
                }
            }
            div.box-image {
                transition: .5s;
                &:nth-child(1) {
                    img {
                        width: 10vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            width: 12vw;
                            transition: 0s;
                            @media screen and (max-width: $size-max-m-1) {
                                width: 14vw;
                                transition: 0s;
                                @media screen and (max-width: $size-max-m-2) {
                                    width: 16vw;
                                    transition: 0s;
                                    @media screen and (max-width: $size-max-m-4) {
                                        width: 22vw;
                                        transition: 0s;
                                    }
                                }
                            }
                        }
                    }
                }
                &:nth-child(2) {
                    img {
                        width: 15vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            width: 17vw;
                            transition: 0s;
                            @media screen and (max-width: $size-max-m-1) {
                                width: 20vw;
                                transition: 0s;
                                @media screen and (max-width: $size-max-m-2) {
                                    width: 24vw;
                                    transition: 0s;
                                    @media screen and (max-width: $size-max-m-4) {
                                        width: 32vw;
                                        transition: 0s;
                                    }
                                }
                            }
                        }
                    }
                }
                &:nth-child(3) {
                    transition: .5s;
                    img {
                        width: 13vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            width: 15vw;
                            transition: 0s;
                            @media screen and (max-width: $size-max-m-1) {
                                width: 18vw;
                                transition: 0s;
                                @media screen and (max-width: $size-max-m-2) {
                                    width: 22vw;
                                    transition: 0s;
                                    @media screen and (max-width: $size-max-m-4) {
                                        width: 28vw;
                                        transition: 0s;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        div.bottom-slide-caption {
            width: 100%;
            height: 15vw;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            position: relative;
            padding-left: 5vw;            
            transition: .5s;
            div.content-unique-img {
                transition: .5s;
                position: absolute;
                top: -5vw;
                @media screen and (max-width: $size-max-3) {
                    top: -10vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        top: 0;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-4) {
                            top: 8vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-5) {
                                top: 0vw;
                                transition: .5s;
                            }
                        }
                    }
                }
                img {
                    width: 13vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        width: 16vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-4) {
                            width: 22vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-5) {
                                width: 30vw;
                                transition: .5s;
                            }
                        }
                    }
                }
            }
        }
    }
}