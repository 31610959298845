
//********************/
// COLORS
//
$color-1: rgb(255, 255, 255); // needed
$color-2: rgb(49, 50, 52); // needed
$color-3: #7ba42e;
$color-4: #e81538;
$color-5: #a3152c;
$color-6: #ffff31;
$color-7: rgba(123, 164, 46, 0.569);
$color-8: #405c0d;

$color-primary: $color-1; // needed
$color-secondary: $color-2; // needed
$color-tertiary: $color-1; // needed

$color-black: #000; // needed
$color-white: #fff; // needed
$color-grey: #58585a; // needed
$color-grey-dark: #353535; // needed body
$color-grey-darker: #262523; // footer
$color-grey-light: #b5b5b5;

$color-error: red; // needed error messages
$color-success: green; // needed success messages

$input_height: 50px;
$input_back_color: #fff;
$input_text_color: #000;
$input_text_size: 20px;

$input_placeholder_color: $color-text-gris;
$input_placeholder_size: 18px;

$input_border_color: $color-primary;
$input_border_radius: 0;
$input_border_size: 1px;
$input_border_style: solid;

$input_margin: 10px 0;
$input_text_color: $color-white;
$input_label_color: $color-white;
$label_checkbox_border_color: $label-color;
$input_checked_color: $color-primary;

$textarea_height: 200px;

$message_errors_text_color: white;
$message_errors_border_color: hsla(0, 100%, 55%, 1);
$message_errors_border_size: 5px;
$message_errors_border_radius: 5px;
$message_errors_background_color: hsla(0, 100%, 55%, 0.7);
$message_success_text_color: white;
$message_success_border_color: hsla(120, 100%, 50%, 1);
$message_success_border_size: 5px;
$message_success_border_radius: 5px;
$message_success_background_color: hsla(120, 100%, 50%, 0.5);


section.section-01-soummission {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: -18vw;
    transition: .5s;
    z-index: 99;
    @media screen and (max-width: 767px) {
        margin-top: 85vw;
        transition: .5s;
        @media screen and (max-width: 667px) {
            margin-top: 90vw;
            transition: .5s;
            @media screen and (max-width: 500px) {
                margin-top: 102vw;
                transition: .5s;
                @media screen and (max-width: 350px) {
                    margin-top: 108vw;
                    transition: .5s;
                }
            }
        }
    }
    div.container-fluid.row {
        top: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        //position: absolute;
        transition: .5s;
        div.box-text-soumission {
            width: 82%;
            display: flex;
            align-items: center;
            justify-content: center;            
            background: $color-white;
            border-radius: 15px;
            transition: .5s;
            -webkit-box-shadow: -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
            -moz-box-shadow:    -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
            box-shadow:         -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
            //margin-left: 2vw;
            transition: .5s;
            padding: 2vw 3vw;
            @media screen and (max-width: 1580px) {
                width: 97%;
                transition: .5s;
                @media screen and (max-width: 991px) {
                    width: 100%;
                    // margin-left: 3vw;
                    transition: .5s;
                }
            }
            p {
                width: 100%;
                font-family: $font-family-3;
                line-height: 18px;
                font-weight: 400;
            }
        }
    }
}

div.section-form.fsb-custom-form {
    display: flex;
    padding: 85px 0;
    align-items: center;
    justify-content: center;
    background: url(../images/bg_section02.jpg) no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
    transition: 0.5s;
    margin-top: -3vw;
    @media screen and (max-width: 991px) {
        margin-top: -6vw;
        transition: .5s;
        @media screen and (max-width: 767px) {
            margin-top: -8vw;
            transition: .5s;
            @media screen and (max-width: 480px) {
                margin-top: -10vw;
                transition: .5s;
            }
        }
    }

    div.border-form {
        width: 80%;
        padding-top: 5vw;
        position: relative;
        border-radius: 15px;
        background: $color-white;

        -webkit-box-shadow: -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
        -moz-box-shadow:    -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
        box-shadow:         -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
        transition: .5s;
        @media screen and (max-width: 1580px) {
            width: 95%;
            transition: .5s;
        }
    }
    div.module-contact-form {
        div.fsb-block-footer-form {
            display: flex;
            flex-direction: column;
            align-content: center;
            align-items: center;
            div.form-group {
                display: flex;
                flex-direction: column;
                align-content: center;
                align-items: center;
            }
        }
    }

    section.input-section,
    div.input-section {
        input[type=text],
        input[type=email],
        input[type=tel] {
            height: 45px;
            font-size: 17px;
            font-weight: 400;
            border-radius: 15px;
            color: $color-black;
            background: $color-7;
            transition: .5s;
            @media screen and (max-width: 1575px) {
                font-size: 14px;
                transition: .5s;
                @media screen and (max-width: 767px) {
                    font-size: 14px;
                    transition: .5s;
                }
            }
        }
        label {
            font-size: 18px;
            font-weight: 500;
            transition: .5s;
            @media screen and (max-width: 1575px) {
                font-size: 15px;
                transition: .5s;
                @media screen and (max-width: 767px) {
                    font-size: 15px;
                    transition: .5s;
                }
            }
        }
        textarea {
            font-size: 17px;
            font-weight: 500;
            border-radius: 15px;
            color: $color-black;
            background: $color-7;
            resize: vertical;
            transition: .5s;
        }
        label.btn-fileDoc {
            width: 50%;
            height: 50px;
            min-width: 300px;
            font-weight: 400;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-white;
            font-family: $font-family-1;
            background: $color-3;
            color: $color-white;
            border-radius: 15px;
            font-size: 18px;
            padding: 0 25px;
            transition: .5s;
            &:hover {
                background: $color-8;
                transition: .5s;
            }
        }
        div.btn-submit {
            button {
                width: 50%;
                height: 50px;
                min-width: 300px;
                font-weight: 400;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $color-white;
                font-family: $font-family-1;
                background: $color-3;
                color: $color-white;
                border-radius: 15px;
                font-size: 18px;
                padding: 0 25px;
                transition: .5s;
                &:hover {
                    background: $color-8;
                    transition: .5s;
                }
            }
        }
    }

    div#fp {
        .preview-file {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            transition: .5s;
        }
    }    

    div.help-block.with-errors {
        ul.list-unstyled {
            li {
                color: #a94442;
                position: absolute;
                line-height: 15px;
                margin-top: -4px;
                font-size: 13px;
                transition: .5s;
            }
        }
    }
    

    div.list-checkbox {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        transition: .5s;
        div.content-checkboxs {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 15px;
            transition: .5s;
            flex-wrap: wrap;
        }
        /* The container */
        .container {
            width: 30%;
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 12px;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            @media screen and (max-width: 1575px) {
                font-size: 15px;
                transition: .5s;
                @media screen and (max-width: 767px) {
                    font-size: 15px;
                    transition: .5s;
                    @media screen and (max-width: 480px) {
                        width: 45%;
                        transition: .5s;
                    }
                }
            }
        }

        /* Hide the browser's default checkbox */
        .container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: $color-grey-light;
            transition: .5s;
        }

        /* On mouse-over, add a grey background color */
        .container:hover input ~ .checkmark {
            background-color: $color-3;
            transition: .5s;
        }

        /* When the checkbox is checked, add a blue background */
        .container input:checked ~ .checkmark {
            background-color: $color-3;
            transition: .5s;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }

        /* Show the checkmark when checked */
        .container input:checked ~ .checkmark:after {
            display: block;
        }

        /* Style the checkmark/indicator */
        .container .checkmark:after {
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            transition: .5s;
        }
    } 
    
    div.content-radios {
        margin-top: 15px;
        transition: .5s;
        /* The container */
        .container {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 18px;
            cursor: pointer;
            font-size: 18px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
        
        /* Hide the browser's default radio button */
        .container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }
        
        /* Create a custom radio button */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: $color-7;
            border-radius: 50%;
        }
        
        /* On mouse-over, add a grey background color */
        .container:hover input ~ .checkmark {
            background-color: #ccc;
        }
        
        /* When the radio button is checked, add a blue background */
        .container input:checked ~ .checkmark {
            background-color: $color-3;
        }
        
        /* Create the indicator (the dot/circle - hidden when not checked) */
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }
        
        /* Show the indicator (dot/circle) when checked */
        .container input:checked ~ .checkmark:after {
            display: block;
        }
        
        /* Style the indicator (dot/circle) */
        .container .checkmark:after {
            top: 9px;
            left: 9px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: white;
        }
    }

    &.form-coordonnees {
        margin-top: -7vw;
        transition: .5s;
        div.border-form {
            width: 88%;
        }        
        div.container {
            width: 92%;
            transition: .5s;
        }
    }
}

div.loading {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display:none;
    background-color: rgba(0,0,0,0.7);
    background-size: cover;
    z-index: 9999;
    &.active {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

#loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border: 16px solid $bg-color-body;
    border-radius: 50%;
    border-top: 16px solid $color-secondary;
    border-bottom: 16px solid $color-primary;
    width: 120px;
    height: 120px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.8), inset 0px 0px 5px rgba(0,0,0,0.8);
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.btn {
    background: $color-3;
    border-radius: 15px;
    transition: .5s;
}