.module-header {
    padding: 3vw;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    background: url(../images/bg_ciel.jpg) no-repeat center center;
    background-size: cover;
    padding-top: 8vw;
    transition: .5s;
    height: 48vw;
    @media screen and (max-width: $size-max-3) {
        height: 54vw;
        padding-top: 12vw;
        transition: .5s;
        @media screen and (max-width: $size-max-m-2) {
            padding-top: 120px;
            transition: .5s;
        }
    }

    &--image {
        border-top: 1px solid $color-white;
        border-radius: 25px;
        padding: 0;
    }

    &--image-mobile {}

    &--caption {
        position: absolute;
        top: 20%;
        width: 100%;
        text-align: center;
        transform: translateY(-40%);
        transform: translateX(5%);
        @media screen and (max-width: $size-max-3) {
            top: 24%;
            transition: .5s;
            @media screen and (max-width: $size-max-m-2) {
                top: 90px;
                transition: .5s;
            }
        }

        &--title {
            width: 100%;
            text-align: left;
            font-size: 22px;
            font-weight: 500;
            color: $color-grey-dark;
            //text-shadow: 0 0 10px $color-black;
            text-shadow: 2px 2px 5px $color-white,
                        -2px -2px 5px $color-white,
                        2px -2px 5px $color-white,
                        -2px 2px 5px $color-white;
        }
        &--subtitle {
            width: 100%;
            text-align: center;
            font-size: 3vw;
            font-weight: 300;
            color: $color-1;
            text-shadow: 0 0 10px $color-black;
        }

    }
}
@media screen and (max-width: $size-md-max) {
    .module-header {
        &--caption {
            &--title {
                font-size: 18px;
            }
            &--subtitle {
                font-size: 4vw;
            }
        }
    }
}
@media screen and (max-width: $size-sm-max) {
    .module-header {
        &--caption {
            &--title {
                font-size: 16px;
            }
            &--subtitle {
                font-size: 5vw;
            }
        }
    }
}
@media screen and (max-width: $size-xs-max) {
    .module-header {
        &--caption {
            &--title {
                font-size: 15px;
            }
            &--subtitle {
                font-size: 6vw;
            }
        }
    }
}
