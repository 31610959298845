body.residential {
    section.section-02 {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        position: relative;
        margin-bottom: 0;
        margin-top: -19vw;
        transition: .5s;
        padding: 0 4vw;
        @media screen and (max-width: $size-max-4) {
            width: 100%;
            flex-direction: column;
            align-items: center;
            transition: .5s;
            @media screen and (max-width: $size-max-m-2) {
                margin-top: 92vw;
                transition: .5s;
                @media screen and (max-width: $size-max-m-3) {
                    margin-top: 105vw;
                    transition: .5s;
                }
            }
        }
        div.left-side-text,
        div.right-side.bursts {
            z-index: 110;
            width: 50%;
            @media screen and (max-width: $size-max-4) {
                width: 100%;
                transition: .5s;
            }
        }
    
        div.left-side-text {
            padding: 3vw;
            background: $color-white;
            border-radius: 15px;
            transition: .5s;
            -webkit-box-shadow: -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
            -moz-box-shadow:    -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
            box-shadow:         -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
            @media screen and (max-width: $size-max-3) {
                padding: 45px 3vw;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    padding: 45px 25px;
                    transition: .5s;
                }
            }
            div.content-paragraphs {
                h3 {
                    margin-bottom: 25px;
                    font-family: $font-family-3; 
                    font-weight: 600;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        color: $color-grey;
                        font-weight: 700;
                        transition: .5s;
                    }
                }
                p {
                    font-size: 18px;
                    line-height: 25px;
                    font-weight: 400;
                    margin-bottom: 25px;
                    font-family: $font-family-3; 
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        font-size: 16px;
                        line-height: 25px;
                        color: $color-grey;
                        transition: .5s;
                    }
                }
            }
            
            div.content-logos {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                margin-top: 3vw;
                flex-wrap: wrap;
                transition: .5s;
                div {
                    width: 25%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        width: 35%;
                        transition: .5s;
                    }
                }
                img {
                    
                    margin: 5px;
                    transition: .5s;
                }
            }
        }
    
        div.right-side.bursts {
            display: flex;
            align-items: flex-end;;
            flex-direction: column;
            justify-content: center;
            position: relative;
            transition: .5s;
            @media screen and (max-width: $size-max-4) {
                margin-top: 45px;
                align-items: center;
                transition: .5s;
            }
    
            div.top-content-bursts {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                transition: .5s;
                @media screen and (max-width: $size-max-4) {
                    margin-top: 45px;
                    align-items: center;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    transition: .5s;
                }
                div.burs-unique {
                    width: 30%;
                    min-width: 150px;
                    height: 350px;
                    padding: 0;
                    margin: 0 10px;            
                    border-radius: 15px;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    display: flex;
                    transition: .5s;
                    @media screen and (max-width: $size-max-2) {
                        height: 280px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            width: 200px;
                            margin-bottom: 25px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-2) {
                                width: 140px;
                                min-width: 100px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-3) {
                                    width: 45%;
                                    min-width: 100px;
                                    transition: .5s;
                                    @media screen and (max-width: 420px) {
                                        width: 70%;
                                        min-width: 100px;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                    &:hover {
                        //transform: scale(0.9);
                        background: $color-3;
                        a.burst-link {
                            background-size: 0 0;
                            background: $color-3;
                            transition: .5s;
                            div.top-burst {
                                h3, h4 {
                                    color: $color-white;
                                    transition: .5s;
                                }
                            }                    
                        }
                        transition: .5s;
                    }
                    a.burst-link {
                        width: 100%;
                        height: 100%;
                        min-height: 350px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: url(../images/bg_menu_degrade_gris.jpg) no-repeat center center $color-3;
                        background-size: cover;
                        flex-direction: column;
                        border-radius: 15px;
                        padding: 0 1.5vw;
                        transition: .5s;
                        -webkit-box-shadow: -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
                        -moz-box-shadow:    -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
                        box-shadow:         -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
                        @media screen and (max-width: $size-max-2) {
                            min-height: 280px;
                            transition: .5s;
                        }
        
                        div.top-burst {
                            width: 100%;
                            height: 65%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            transition: .5s;
                            h3, h4 {
                                width: 100%;
                                color: $color-4;
                                transition: .5s; 
                                text-align: center;                                              
                                text-transform: uppercase;
                                //text-shadow: 1px -0px 1px $color-grey;
                            }
                            h3 {
                                font-size: 2.0vw;
                                font-weight: bold;
                                @media screen and (max-width: $size-max-4) {
                                    text-align: center;
                                    font-size: 3.2vw;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-2) {
                                        font-size: 25px;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-2) {
                                            font-size: 22px;
                                            transition: .5s;
                                        }
                                    }
                                }
                            }
                            h4 {
                                font-family: $font-family-3; 
                                font-size: 1.1vw;
                                font-weight: bold;
                                margin-top: 15px;
                                @media screen and (max-width: $size-max-4) {
                                    text-align: center;
                                    font-size: 2.2vw;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-2) {
                                        font-size: 18px;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-2) {
                                            font-size: 16px;
                                            transition: .5s;
                                        }
                                    }
                                }
                            }
                        }
                        div.bottom-burst {
                            width: 100%;
                            height: 45%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-top: 3px solid $color-white;
                            flex-direction: column;
                            transition: .5s;
                        }
                    }
                }
            }    
            
            div.bottom-content-gov-logos {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 65px 15px;
                transition: .5s;
                @media screen and (max-width: $size-max-m-3) {
                    flex-direction: column;
                    transition: .5s;
                }
                div.left-logo,
                div.middle-burst,
                div.right-logos {
                    width: 30%;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-3) {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: .5s;
                    }
                }
                div.left-logo {
                    @media screen and (max-width: $size-max-m-3) {
                        position: absolute;
                        transition: .5s;
                    }
                }
                div.middle-burst {
                    width: 12.555vw;
                    min-width: 220px;
                    height: 250px;
                    padding: 0;
                    margin: 0 10px;            
                    border-radius: 15px;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    background: $color-transparent-1;
                    transition: .5s;
                    cursor: pointer;
                    display: flex;
                    -webkit-box-shadow: -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
                    -moz-box-shadow:    -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
                    box-shadow:         -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
                    margin-right: -1.5vw;
                    @media screen and (max-width: $size-max-1) {
                        margin-right: 0;
                        transition: .5s;
                        @media screen and (max-width: $size-max-2) {
                            width: 12.855vw;
                            min-width: 150px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                width: 13.855vw;
                                min-width: 150px;
                                margin-right: 1.5vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-4) {
                                    width: 18.855vw;
                                    min-width: 150px;
                                    margin-right: 1.5vw;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-1) {
                                        width: 20vw;
                                        min-width: 150px;
                                        margin-right: 1.5vw;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-3) {
                                            width: 40vw;
                                            min-width: 150px;
                                            transition: .5s;
                                            @media screen and (max-width: $size-max-m-5) {
                                                width: 65vw;
                                                min-width: 150px;
                                                transition: .5s;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &:hover {
                        transition: .5s;
                        background: $color-3;
                        a.box-unique-center {
                            h3, h4 {
                                color: $color-white;
                                transition: .5s;
                            }
                        }
                    }
                    a.box-unique-center {
                        h3, h4 {
                            color: $color-grey;
                            text-transform: uppercase;
                            text-align: center;
                            width: 100%;
                        }
                        h3 {
                            transition: .5s;
                            &:nth-child(1) {
                                font-size: 1.2vw;
                                font-weight: 400;
                                @media screen and (max-width: $size-max-2) {
                                    font-size: 1.5vw;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-4) {
                                        font-size: 2.2vw;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-3) {
                                            font-size: 3.5vw;
                                            transition: .5s;
                                        }
                                    }
                                }
                            }
                            &:nth-child(2) {
                                margin: 10px 0;
                                font-size: 2.4vw;
                                font-weight: 600;
                                @media screen and (max-width: $size-max-2) {
                                    font-size: 3.0vw;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-4) {
                                        font-size: 3.8vw;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-3) {
                                            font-size: 8.2vw;
                                            transition: .5s;
                                        }
                                    }
                                }
                            }
                        }
                        h4 {
                            font-size: 1.2vw;
                            font-weight: 400;
                            @media screen and (max-width: $size-max-2) {
                                font-size: 1.5vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-4) {
                                    font-size: 2.2vw;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-3) {
                                        font-size: 3.5vw;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                }
                div.right-logos {
                    display: flex;
                    align-items: flex-end;
                    flex-direction: column;
                    justify-content: center;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-3) {
                        margin-top: 65px;
                        flex-wrap: wrap;
                        align-items: center;
                        flex-direction: row;
                        transition: .5s;
                    }
                    div {                    
                        margin: 15px 0;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-3) {
                            margin: 15px;
                            transition: .5s;
                        }
                    }
                }
            }
        }
    }

    section.section-03-residentiel {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .5s;
        div.container-fluid.row {
            width: 96%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            padding: 65px 15px;
            transition: .5s;
            @media screen and (max-width: $size-max-4) {
                flex-direction: column-reverse;
                transition: .5s;
            }

            div.left-side-sect-03,
            div.right-side-sect-03 {
                width: 50%;
                transition: .5s;
                @media screen and (max-width: $size-max-4) {
                    width: 100%;
                    transition: .5s;
                }
            }            
            div.left-side-sect-03 {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                padding-left: 2vw;
                @media screen and (max-width: $size-max-4) {
                    align-items: center;
                    margin-top: 35px;
                    transition: .5s;
                }
                ul.list-services {
                    width: 50%;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-3) {
                        width: 100%;
                        transition: .5s;
                    }
                    li.item {
                        color: $color-3;
                        font-size: 1.4vw;
                        line-height: 1.4vw;
                        margin-bottom: 1.2vw;
                        text-transform: none;
                        font-family: $font-family-3;
                        font-weight: 600;
                        transition: .5s;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 1.7vw;
                            line-height: 1.8vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-4) {
                                font-size: 20px;
                                line-height: 25px;
                                transition: .5s;
                            }
                        }
                    }
                }
                div.content-flor {
                    width: 50%;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    transition: .5s;
                }
            }
            div.right-side-sect-03 {
                
                display: flex;
                align-items: center;
                justify-content: center;
                padding-left: 1vw;
                div.box-text {
                    padding: 4vw;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: flex-start;
                    background: $color-white;
                    border-radius: 15px;
                    transition: .5s;
                    -webkit-box-shadow: -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
                    -moz-box-shadow:    -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
                    box-shadow:         -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
                    @media screen and (max-width: $size-max-3) {
                        padding: 45px 3vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            padding: 45px 25px;
                            transition: .5s;
                        }
                    }
                    p {
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 35px;
                        margin-bottom: 25px;
                        text-align: left;
                        font-family: $font-family-3; 
                        transition: .5s;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 22px;
                            line-height: 32px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                font-size: 22px;
                                line-height: 25px;
                                transition: .5s;
                            }
                        }
                        a {
                            color: $color-3;
                            transition: .5s;
                            &:hover {
                                color: $color-grey;
                                transition: .5s;
                            }
                        }
                        &.bold-text {
                            font-weight: 600;
                            font-family: $font-family-1; 
                        }
                        @media screen and (max-width: $size-max-m-1) {
                            font-size: 16px;
                            line-height: 20px;
                            color: $color-grey;
                            transition: .5s;
                        }
                    }
                }
            }
        }
    }
    
    section.section-03-index {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 65px;
        // margin-top: 65px;
        transition: .5s;
        @media screen and (max-width: $size-max-3) {     
            margin-top: 65px;   
            transition: .5s;
        }
        div.container-fluid.row {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                flex-direction: column;
                transition: .5s;
            }
            &.multiBoxs {
                flex-wrap: wrap;
                justify-content: center;
                div {
                    margin: 15px;
                }
            }
    
            div.left-burst-inactive,
            div.right-burst-active {
                width: 47%;
                border-radius: 15px;
                background: transparent;
                box-shadow: none;
                transition: .5s;
                border: 0;  
                @media screen and (max-width: $size-max-m-1) {
                    width: 100%;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        min-height: 210px;
                        height: auto;
                        transition: .5s;
                    }
                }          
            }
    
            div.left-burst-inactive {
                display: flex;
                min-height: 210px;            
                align-items: center;
                justify-content: center;
                -webkit-box-shadow: 0px 5px 10px 0px rgba(32, 32, 32, 0.75);
                -moz-box-shadow:    0px 5px 10px 0px rgba(32, 32, 32, 0.75);
                box-shadow:         0px 5px 10px 0px rgba(32, 32, 32, 0.75);
                background: $color-3;
                transition: .5s;
                padding: 0;
                @media screen and (max-width: $size-max-m-1) {
                    margin-bottom: 35px;
                    transition: .5s;
                }
                div.content-elts {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    padding-left: 5vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        padding: 10px;
                        transition: .5s;
                    }
                    a.border-elts {
                        width: 100%;
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        flex-direction: column;
                        border-left: 5px solid $color-white;
                        padding-bottom: 0;
                        padding-left: 15px;
                        padding-top: 0;                   
                        margin-top: 5px;
                        transition: .5s;
                        h3, p {
                            font-family: $font-family-3;                        
                            color: $color-white;
                            transition: .5s;
                        }
                        h3 {
                            &:nth-child(1) {
                                font-weight: 600;
                                text-transform: none;
                                font-size: 1.8vw;
                                @media screen and (max-width: $size-max-4) {
                                    font-size: 25px;
                                    transition: .5s;
                                }
                            }
                            &:nth-child(2) {
                                font-weight: 600;
                                text-transform: uppercase;
                                font-size: 1.8vw;
                                @media screen and (max-width: $size-max-4) {
                                    font-size: 20px;
                                    transition: .5s;
                                }
                            }
                        }
    
                        p {                        
                            font-size: 20px;
                            font-weight: 400;
                            line-height: 25px;
                            margin-top: 15px;
                            @media screen and (max-width: $size-max-4) {
                                font-size: 18px;
                                line-height: 20px;
                                transition: .5s;
                            }
                            b {
                                font-weight: 600;
                            }
                        }                                      
                    }
                    a.inner-link {
                        width: 100%;                    
                        transition: .5s;
                        color: $color-6;
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 25px;
                        padding-left: 20px;
                        padding-bottom: 15px;
                        @media screen and (max-width: $size-max-m-3) {
                            font-size: 14px;
                            transition: .5s;
                        }
                        &:hover {
                            color: $color-grey;
                            transition: .5s;
                        }
                    }              
                }
                &:hover {
                    background: $color-white;
                    transition: .5s;
                    div.content-elts {
                        a.border-elts {
                            h3, p {                       
                                color: $color-4;
                                transition: .5s;
                            }
                        }
                        a.inner-link {
                            color: $color-4;
                            transition: .5s;
                        }
                    }                
                }
            }
    
            div.right-burst-active {
                height: 210px;  
                display: flex;
                align-items: center;
                justify-content: center;            
                transition: .5s;
                padding: 0;
                @media screen and (max-width: $size-max-m-2) {
                    height: auto;
                    min-height: 210px;
                    transition: .5s;
                }         
                div.centralizator {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    -webkit-box-shadow: 0px 5px 10px 0px rgba(32, 32, 32, 0.75);
                    -moz-box-shadow:    0px 5px 10px 0px rgba(32, 32, 32, 0.75);
                    box-shadow:         0px 5px 10px 0px rgba(32, 32, 32, 0.75);
                    background-image: linear-gradient(180deg, #c3c3c3, #acacac); /* Standard syntax (must be last) */
                    border-radius: 15px;
                    overflow: hidden;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        padding: 15px;
                        @media screen and (max-width: $size-max-m-2) {
                            flex-direction: column;
                            transition: .5s;
                            padding: 0;
                        }
                    }
                    &:hover {
                        transition: .5s;
                        background: $color-3;
                        a.link-active-burst {
                            transition: .5s;
                            div.conent-img {
                                transition: .5s;
                                img {
                                    transition: .5s;
                                    &:nth-child(1) {
                                        opacity: 0;
                                    }
                                    &:nth-child(2) {
                                        opacity: 1;
                                    }
                                }
                            }
                            div.content-text {
                                transition: .5s;
                                h4,
                                h3 {
                                    color: $color-white;
                                    transition: .5s;
                                }
                            }
                        }                        
                    }
                    a.link-active-burst {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @media screen and (max-width: $size-max-m-2) {
                            width: 100%;
                            padding: 15px;
                            transition: .5s;
                        }                        
                        div.conent-img {
                            width: 45%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            transition: .5s;
                            img {
                                transition: .5s;
                                &:nth-child(1) {
                                    opacity: 1;
                                }
                                &:nth-child(2) {
                                    position: absolute;
                                    opacity: 0;
                                }
                            }
                        }
                        div.content-text {
                            width: 65%;
                            display: flex;
                            align-items: flex-start;
                            justify-content: center;
                            flex-direction: column;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-2) {
                                padding-left: 10px;
                                transition: .5s;
                            }
                            h4 {
                                font-weight: 500;
                                color: $color-grey;
                                font-size: 1.4vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-4) {
                                    font-size: 2.0vw;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-1) {
                                        font-size: 22px;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-2) {
                                            font-size: 20px;
                                            transition: .5s;
                                            @media screen and (max-width: $size-max-m-4) {
                                                font-size: 18px;
                                                transition: .5s;
                                            }
                                        }
                                    }
                                }
                            }
                            h3 {
                                text-transform: none;
                                color: $color-grey;
                                font-size: 1.8vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-4) {
                                    font-size: 2.4vw;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-1) {
                                        font-size: 27px;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-2) {
                                            font-size: 24px;
                                            transition: .5s;
                                            @media screen and (max-width: $size-max-m-4) {
                                                font-size: 22px;
                                                transition: .5s;
                                                @media screen and (max-width: $size-max-m-5) {
                                                    font-size: 18px;
                                                    transition: .5s;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}