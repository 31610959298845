section.header {
    background-repeat: no-repeat;
    background-size: contain;
    @extend .flex;
    @extend .justify-center;
    @extend .items-center;
    h2 {
        @extend .text-color-contrast;
        @extend .font-1-normal;
        text-align: center;
        font-size: 3.5vw;
        font-weight: 300;
        text-transform: uppercase;
        transform: translateY(-30px);
        span.subtitle {
            font-weight: 500;
            font-size: 2.2vw;
            letter-spacing: 1vw;
        }
    }
}
