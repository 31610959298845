// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

html {
    overflow: hidden;
}

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

section.section-02-index {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    margin-bottom: 0;
    margin-top: -10vw;
    transition: .5s;
    padding: 0 4vw;
    @media screen and (max-width: $size-max-4) {
        width: 100%;
        flex-direction: column;
        align-items: center;
        transition: .5s;
    }
    div.left-side-text,
    div.right-side.bursts {
        z-index: 110;
        width: 50%;
        @media screen and (max-width: $size-max-4) {
            width: 100%;
            transition: .5s;
        }
    }

    div.left-side-text {
        padding: 3vw;
        background: $color-white;
        border-radius: 15px;
        transition: .5s;
        -webkit-box-shadow: -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
        -moz-box-shadow:    -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
        box-shadow:         -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
        @media screen and (max-width: $size-max-3) {
            padding: 45px 3vw;
            transition: .5s;
            @media screen and (max-width: $size-max-m-2) {
                padding: 45px 25px;
                transition: .5s;
            }
        }
        div.content-paragraphs {
            h3 {
                margin-bottom: 25px;
                font-family: $font-family-3; 
                font-weight: 600;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    color: $color-grey;
                    font-weight: 700;
                    transition: .5s;
                }
            }
            p {
                font-size: 18px;
                line-height: 25px;
                font-weight: 400;
                margin-bottom: 25px;
                font-family: $font-family-3; 
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    font-size: 16px;
                    line-height: 20px;
                    color: $color-grey;
                    transition: .5s;
                }
            }
        }
        
        div.content-logos {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin-top: 3vw;
            flex-wrap: wrap;
            transition: .5s;
            div {
                width: 25%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    width: 35%;
                    transition: .5s;
                }
            }
            img {
                
                margin: 5px;
                transition: .5s;
            }
        }
    }

    div.right-side.bursts {
        display: flex;
        align-items: flex-end;;
        flex-direction: column;
        justify-content: center;
        position: relative;
        transition: .5s;
        @media screen and (max-width: $size-max-4) {
            margin-top: 45px;
            align-items: center;
            transition: .5s;
        }

        div.top-content-bursts {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            transition: .5s;
            @media screen and (max-width: $size-max-4) {
                margin-top: 45px;
                align-items: center;
                justify-content: space-around;
                flex-wrap: wrap;
                transition: .5s;
            }
            div.burs-unique {
                width: 30%;
                min-width: 150px;
                height: 350px;
                padding: 0;
                margin: 0 10px;            
                border-radius: 15px;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                display: flex;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    height: 280px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        width: 200px;
                        margin-bottom: 25px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            width: 140px;
                            min-width: 100px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-3) {
                                width: 45%;
                                min-width: 100px;
                                transition: .5s;
                                @media screen and (max-width: 420px) {
                                    width: 70%;
                                    min-width: 100px;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
                &:hover {
                    //transform: scale(0.9);
                    background: $color-3;
                    a.burst-link {
                        background-size: 0 0;
                        background: $color-3;
                        transition: .5s;
                        div.top-burst {
                            h3, h4 {
                                color: $color-white;
                                transition: .5s;
                            }
                        }                    
                    }
                    transition: .5s;
                }
                a.burst-link {
                    width: 100%;
                    height: 100%;
                    min-height: 350px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: url(../images/bg_menu_degrade_gris.jpg) no-repeat center center $color-3;
                    background-size: cover;
                    flex-direction: column;
                    border-radius: 15px;
                    padding: 0 1.5vw;
                    transition: .5s;
                    -webkit-box-shadow: -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
                    -moz-box-shadow:    -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
                    box-shadow:         -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
                    @media screen and (max-width: $size-max-2) {
                        min-height: 280px;
                        transition: .5s;
                    }
    
                    div.top-burst {
                        width: 100%;
                        height: 65%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        transition: .5s;
                        h3, h4 {
                            width: 100%;
                            color: $color-4;
                            transition: .5s;                                               
                            text-transform: uppercase;
                            //text-shadow: 1px -0px 1px $color-grey;
                        }
                        h3 {
                            font-size: 2.2vw;
                            font-weight: bold;
                            @media screen and (max-width: $size-max-4) {
                                text-align: center;
                                font-size: 3.2vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    font-size: 25px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-2) {
                                        font-size: 22px;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                        h4 {
                            font-family: $font-family-3; 
                            font-size: 1.4vw;
                            font-weight: bold;
                            margin-top: 15px;
                            @media screen and (max-width: $size-max-4) {
                                text-align: center;
                                font-size: 2.2vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    font-size: 18px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-2) {
                                        font-size: 16px;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                    div.bottom-burst {
                        width: 100%;
                        height: 45%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-top: 3px solid $color-white;
                        flex-direction: column;
                        transition: .5s;
                    }
                }
            }
        }    
        
        div.bottom-content-gov-logos {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 65px 15px;
            transition: .5s;
            @media screen and (max-width: $size-max-m-3) {
                flex-direction: column;
                transition: .5s;
            }
            div.left-logo,
            div.middle-burst,
            div.right-logos {
                width: 30%;
                transition: .5s;
                @media screen and (max-width: $size-max-m-3) {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: .5s;
                }
            }
            div.left-logo {
                @media screen and (max-width: $size-max-m-3) {
                    position: absolute;
                    transition: .5s;
                }
            }
            div.middle-burst {
                width: 12.555vw;
                min-width: 220px;
                height: 250px;
                padding: 0;
                margin: 0 10px;            
                border-radius: 15px;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                background: $color-transparent-1;
                transition: .5s;
                cursor: pointer;
                display: flex;
                -webkit-box-shadow: -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
                -moz-box-shadow:    -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
                box-shadow:         -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
                margin-right: -1.5vw;
                @media screen and (max-width: $size-max-1) {
                    margin-right: 0;
                    transition: .5s;
                    @media screen and (max-width: $size-max-2) {
                        width: 12.855vw;
                        min-width: 150px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            width: 13.855vw;
                            min-width: 150px;
                            margin-right: 1.5vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-4) {
                                width: 18.855vw;
                                min-width: 150px;
                                margin-right: 1.5vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    width: 20vw;
                                    min-width: 150px;
                                    margin-right: 1.5vw;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-3) {
                                        width: 40vw;
                                        min-width: 150px;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-5) {
                                            width: 65vw;
                                            min-width: 150px;
                                            transition: .5s;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &:hover {
                    transition: .5s;
                    background: $color-3;
                    a.box-unique-center {
                        h3, h4 {
                            color: $color-white;
                            transition: .5s;
                        }
                    }
                }
                a.box-unique-center {
                    h3, h4 {
                        color: $color-grey;
                        text-transform: uppercase;
                        text-align: center;
                        width: 100%;
                    }
                    h3 {
                        transition: .5s;
                        &:nth-child(1) {
                            font-size: 1.2vw;
                            font-weight: 400;
                            @media screen and (max-width: $size-max-2) {
                                font-size: 1.5vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-4) {
                                    font-size: 2.2vw;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-3) {
                                        font-size: 3.5vw;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                        &:nth-child(2) {
                            margin: 10px 0;
                            font-size: 2.4vw;
                            font-weight: 600;
                            @media screen and (max-width: $size-max-2) {
                                font-size: 3.0vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-4) {
                                    font-size: 3.8vw;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-3) {
                                        font-size: 8.2vw;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                    h4 {
                        font-size: 1.2vw;
                        font-weight: 400;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 1.5vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-4) {
                                font-size: 2.2vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-3) {
                                    font-size: 3.5vw;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }
            div.right-logos {
                display: flex;
                align-items: flex-end;
                flex-direction: column;
                justify-content: center;
                transition: .5s;
                @media screen and (max-width: $size-max-m-3) {
                    margin-top: 65px;
                    flex-wrap: wrap;
                    align-items: center;
                    flex-direction: row;
                    transition: .5s;
                }
                div {                    
                    margin: 15px 0;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-3) {
                        margin: 15px;
                        transition: .5s;
                    }
                }
            }
        }
    }
}

body.form {
    section.section-01-contact {
        width: 100%;
        display: flex;
        align-items:center;
        justify-content: center;        
        background-size: 100% 100%;
        position: relative;
        margin-top: -25vw;
        transition: .5s;
        @media screen and (max-width: $size-max-m-2) {
            margin-top: 85vw;
            transition: .5s;
            @media screen and (max-width: $size-max-m-4) {
                margin-top: 95vw;
                transition: .5s;
            }
        }
        div.container-fluid.row {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;            
            padding: 5vw 6vw;
            transition: .5s;
            @media screen and (max-width: $size-max-3) {
                padding: 5vw 8vw;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    padding: 4vw 6vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        padding: 45px 45px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-4) {
                            padding: 45px 15px;
                            transition: .5s;
                        }
                    }
                }
            }
            div.center-container {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: $color-white;                
                //flex-direction: row;
                min-height: 20vw;
                transition: .5s;
                -webkit-box-shadow: -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
                -moz-box-shadow:    -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
                box-shadow:         -5px 0px 9px 0px rgba(0, 0, 0, 0.46);
                border-radius: 15px;
                overflow: hidden;
                @media screen and (max-width: $size-max-m-1) {
                    justify-content: center;
                    flex-direction: column;
                    transition: .5s;
                }
                div.left-side-sect-01-contact {
                    width: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;                    
                    flex-direction: column;
                    padding: 15px 5vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        width: 100%;
                        padding: 15px 15px;
                        transition: .5s;
                    }
                    div.header-sect-contact,
                    div.body-infos {
                        width: 100%;
                        transition: .5s;
                    }                    
                    div.header-sect-contact {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        @media screen and (max-width: $size-max-3) {
                            padding-top: 25px;
                            transition: .5s;
                        }
                        @media screen and (max-width: $size-max-m-1) {                            
                            justify-content: space-between;
                            margin-bottom: 15px;
                            transition: .5s;
                        }
                        h3.title-set-contact {
                            width: 100%;
                            text-align: left;
                            font-family: $font-family-1;
                            color: $color-grey-dark;
                            line-height: 32px;
                            font-weight: 500;
                            font-size: 1.4vw;
                            @media screen and (max-width: $size-max-3) {
                                font-size: 22px;
                                line-height: 20px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    line-height: 20px;
                                    transition: .5s;
                                }
                            }                            
                        }
                    }
                    div.body-infos {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        flex-direction: column;
                        margin-top: 2.5vw;
                        transition: .5s;
                        div.line-info {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            margin-bottom: 2vw;
                            transition: .5s;
                            &:nth-child(1) {
                                align-items: flex-start;
                            }
                            @media screen and (max-width: $size-max-m-1) {                            
                                margin-bottom: 25px;
                                transition: .5s;
                            }                        
                            h3.infos {
                                font-size: 1.5vw;
                                font-weight: 400;
                                margin-left: 15px;
                                line-height: 1.8vw;
                                text-transform: none;
                                color: $color-grey-dark;
                                transition: .5s;
                                &.message {
                                    font-weight: 500;
                                    a {
                                        font-weight: 500;
                                    }
                                }
                                @media screen and (max-width: $size-max-m-1) {
                                    font-size: 2.0vw;
                                    line-height: 2.8vw;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-2) {
                                        font-size: 18px;
                                        line-height: 22px;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-5) {
                                            font-size: 16px;
                                            line-height: 20px;
                                            margin-left: 5px;
                                            transition: .5s;
                                        }
                                    }
                                }
                                &:hover {
                                    transition: .5s;
                                    a {
                                        color: $color-3;
                                        transition: .5s;
                                    }
                                }
                                a {
                                    font-weight: 400;
                                    text-transform: none;
                                    color: $color-grey-dark;
                                    transition: .5s;
                                }
                            }
                            img {
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    width: 30px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-5) {
                                        min-width: 40px;
                                        width: 40px;
                                        transition: .5s;
                                    }
                                }
                            }
                            div.icon {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                transition: .5s;
                                img {
                                    min-width: 50px;
                                    max-width: 75px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-3) {
                                        min-width: 30px;
                                        transition: .5s;
                                    }
                                    @media screen and (max-width: $size-max-m-1) {
                                        min-width: 30px;
                                        width: 30px;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-5) {
                                            min-width: 30px;
                                            width: 30px;
                                            transition: .5s;
                                        }
                                    }
                                    &:nth-child(1) {
                                        opacity: 1;
                                    }
                                    &:nth-child(2) {
                                        position: absolute;
                                        opacity: 0;
                                    }
                                }
                            }                            
                            &:hover {                                
                                transition: .5s;                                
                                div.icon {
                                    transition: .5s;
                                    img {
                                        transition: .5s;
                                        &:nth-child(1) {
                                            opacity: 0;
                                        }
                                        &:nth-child(2) {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                            &:nth-child(2) {
                                h3.infos {
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    flex-direction: column;
                                    transition: .5s;
                                    a {
                                        width: 100%;
                                        font-size: 1.8vw;
                                        font-weight: 500;
                                        margin-left: 15px;
                                        line-height: 2.0vw;
                                        color: $color-grey-dark;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-1) {
                                            font-size: 2.8vw;
                                            line-height: 3.2vw;
                                            transition: .5s;
                                            @media screen and (max-width: $size-max-m-2) {
                                                font-size: 28px;
                                                line-height: 32px;
                                                transition: .5s;
                                                @media screen and (max-width: $size-max-m-5) {
                                                    font-size: 22px;
                                                    line-height: 28px;
                                                    margin-left: 5px;
                                                    transition: .5s;
                                                }
                                            }
                                        }
                                    }
                                    &:hover {
                                        transition: .5s;
                                        a {
                                            color: $color-3;
                                            transition: .5s;
                                        }
                                    }                                                                   
                                }
                            }
                        }
                    }
                }
                div.right-side-sect-02-contact {
                    width: 50%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    //background: $color-grey-darker;
                    transition: .5s;
                    padding: 0;
                    @media screen and (max-width: $size-max-m-1) {
                        width: 100%;
                        transition: .5s;
                    }
                    div.module-google-map {
                        width: 100%;
                        transition: .5s;
                    }
                }
            }
        }
    }
}

section.section-03-index {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 65px;
    // margin-top: 65px;
    transition: .5s;
    @media screen and (max-width: $size-max-3) {     
        margin-top: 65px;   
        transition: .5s;
    }
    div.container-fluid.row {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        transition: .5s;
        @media screen and (max-width: $size-max-m-1) {
            flex-direction: column;
            transition: .5s;
        }
        &.multiBoxs {
            flex-wrap: wrap;
            justify-content: center;
            div {
                margin: 15px;
            }
        }

        div.left-burst-inactive,
        div.right-burst-active {
            width: 47%;
            border-radius: 15px;
            background: transparent;
            box-shadow: none;
            transition: .5s;
            border: 0;  
            @media screen and (max-width: $size-max-m-1) {
                width: 100%;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    min-height: 210px;
                    height: auto;
                    transition: .5s;
                }
            }          
        }

        div.left-burst-inactive {
            display: flex;
            min-height: 210px;            
            align-items: center;
            justify-content: center;
            -webkit-box-shadow: 0px 5px 10px 0px rgba(32, 32, 32, 0.75);
            -moz-box-shadow:    0px 5px 10px 0px rgba(32, 32, 32, 0.75);
            box-shadow:         0px 5px 10px 0px rgba(32, 32, 32, 0.75);
            background: $color-3;
            transition: .5s;
            padding: 0;
            @media screen and (max-width: $size-max-m-1) {
                margin-bottom: 35px;
                transition: .5s;
            }
            div.content-elts {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding-left: 5vw;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    padding: 10px;
                    transition: .5s;
                }
                a.border-elts {
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-direction: column;
                    border-left: 5px solid $color-white;
                    padding-bottom: 0;
                    padding-left: 15px;
                    padding-top: 15px;                   
                    margin-top: 5px;
                    transition: .5s;
                    h3, p {
                        font-family: $font-family-3;                        
                        color: $color-white;
                        transition: .5s;
                    }
                    h3 {
                        &:nth-child(1) {
                            font-weight: 600;
                            text-transform: none;
                            font-size: 1.8vw;
                            @media screen and (max-width: $size-max-4) {
                                font-size: 25px;
                                transition: .5s;
                            }
                        }
                        &:nth-child(2) {
                            font-weight: 600;
                            text-transform: uppercase;
                            font-size: 1.8vw;
                            @media screen and (max-width: $size-max-4) {
                                font-size: 20px;
                                transition: .5s;
                            }
                        }
                    }

                    p {
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 25px;
                        text-transform: none;
                        margin-top: 15px;
                        @media screen and (max-width: $size-max-4) {
                            font-size: 18px;
                            line-height: 20px;
                            transition: .5s;
                        }
                        b {
                            font-weight: 600;
                        }
                    }                                      
                }
                a.inner-link {
                    width: 100%;                    
                    transition: .5s;
                    color: $color-6;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 25px;
                    padding-left: 20px;
                    padding-bottom: 15px;
                    @media screen and (max-width: $size-max-m-3) {
                        font-size: 14px;
                        transition: .5s;
                    }
                    &:hover {
                        color: $color-grey;
                        transition: .5s;
                    }
                }              
            }
            &:hover {
                background: $color-white;
                transition: .5s;
                div.content-elts {
                    a.border-elts {
                        h3, p {                       
                            color: $color-4;
                            transition: .5s;
                        }
                    }
                    a.inner-link {
                        color: $color-4;
                        transition: .5s;
                    }
                }                
            }
        }

        div.right-burst-active {
            height: 210px;  
            display: flex;
            align-items: center;
            justify-content: center;            
            transition: .5s;
            padding: 0;
            @media screen and (max-width: $size-max-m-2) {
                height: auto;
                min-height: 210px;
                transition: .5s;
            }         
            div.centralizator {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                -webkit-box-shadow: 0px 5px 10px 0px rgba(32, 32, 32, 0.75);
                -moz-box-shadow:    0px 5px 10px 0px rgba(32, 32, 32, 0.75);
                box-shadow:         0px 5px 10px 0px rgba(32, 32, 32, 0.75);
                background-image: linear-gradient(180deg, #c3c3c3, #acacac); /* Standard syntax (must be last) */
                border-radius: 15px;
                overflow: hidden;
                transition: .3s;
                @media screen and (max-width: $size-max-4) {
                    padding: 15px;
                    @media screen and (max-width: $size-max-m-2) {
                        flex-direction: column;
                        transition: .5s;
                        padding: 0;
                    }
                }
                a.link-active-burst {
                    width: 50%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media screen and (max-width: $size-max-m-2) {
                        width: 100%;
                        transition: .5s;
                    }
                    div.left-elt-burst-bottom,
                    div.right-elt-burst-bottom {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: .5s;
                    }
                    &:nth-child(1),
                    &:nth-child(2)  {
                        &:hover {
                            transition: .5s;                            
                            div.left-elt-burst-bottom,
                            div.right-elt-burst-bottom {
                                background: $color-3;
                                transition: .5s;
                                div.content-border {
                                    transition: .5s;
                                    div.content-img {
                                        transition: .5s;
                                        img {
                                            transition: .5s;
                                            filter: invert(100%) sepia(0%) saturate(0) hue-rotate(170deg) brightness(104%) contrast(245%);
                                        }
                                    }                       
                                    div.content-text {
                                        a, h3 {
                                            color: $color-white;
                                            transition: .5s;
                                        }      
                                    }                                    
                                }
                                a, h3 {
                                    color: $color-white;
                                    transition: .5s;
                                }
                                img {
                                    transition: .5s;
                                    filter: invert(100%) sepia(0%) saturate(0) hue-rotate(170deg) brightness(104%) contrast(245%);
                                }                  
                            }
                            div.content-border {
                                div.content-text {
                                    h3 {
                                        color: $color-grey;
                                        text-shadow: 1px -0px 1px $color-grey;
                                        transition: .5s;
                                    }
                                }
                            }                
                        }
                    }
                }                
                div.left-elt-burst-bottom {
                    height: 100%;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        padding: 15px 0;
                        transition: .5s;
                    }
                    div.content-border {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    div.content-text {
                        @media screen and (max-width: $size-max-4) {
                            padding: 10px;
                            transition: .5s;
                        }
                        h3 {
                            margin-left: 1.2vw;
                            color: $color-4;
                            font-size: 1.8vw;
                            font-weight: 600;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                font-size: 3.5vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    font-size: 25px;
                                    transition: .5s;
                                }
                            }
                            &:nth-child(1) {
                                text-transform: none;
                            }
                            &:nth-child(2) {
                                text-transform: uppercase;
                            }
                        }
                    }
                    div.content-img {
                        img {       
                            transition: .5s;                     
                            @media screen and (max-width: $size-max-m-2) {
                                width: 85px;
                                transition: .5s;
                            }
                        }
                    }
                }
                div.right-elt-burst-bottom {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border-left: 3px solid $color-grey-light-2;
                    transition: .5s;                    
                    @media screen and (max-width: $size-max-4) {
                        padding: 10px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            border: 0;
                            margin-top: 15px;
                            transition: .5s;
                        }
                    }
                    h3 {
                        font-family: $font-family-3;
                        text-transform: none;
                        font-weight: 600;
                        transition: .5s;
                    }
                   .text-1 {                       
                        color: $color-grey;                       
                    }
                    .text-2 {
                        margin-top: 5px;
                        color: $color-3;
                    }
                }
            }
        }
    }
}

div.content-guide-PDF {
    .btn-PDF-guide {
        font-size: 18px;
        font-weight: 400;        
        color: $color-white;        
        padding: 8px 25px;
        background: $color-grey;
        border: 1px solid transparent;
        border-radius: 5px;
        transition: .5s;
        i {
            color: $color-white;
            margin-right: 12px;
            transition: .5s;
        }
        &:hover {
            color: $color-5;
            border: 1px solid $color-5;
            background: transparent;
            transition: .5s;
            i {
                color: $color-5;
            }
        }
    }
    .header-title {
        text-transform: uppercase;
        transition: .5s;
    }
}


div.empty-data {
    width: 100%;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
    h3.empty-title {
        width: 100%;
        text-transform: none;
        text-align: center;
        font-weight: 400;
        font-size: 17px;
        color: #ccc;
    }
}

div#bodyContent {
    p, a {
        color: $color-3;
    }
    a {
        transition: .5s;
        &:hover {
            color: $color-grey-dark;
            transition: .5s;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or module-menu-centre.php
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Sections (to use with /modules/module-*-*.php)
/*****************************************************/
// @import 'modules/module-slideshow';

/* purgecss start ignore */
@import 'modules/module-slideshow-concave3D';
@import 'modules/module-slideshow-concave';
/* purgecss end ignore */


@import 'modules/module-header';
@import 'modules/module-soumission';


/*****************************************************/
/* PAGES INCLUDES
/*****************************************************/
@import 'pages/page-residential';
@import 'pages/page-commercial';
@import 'pages/page-realisations';

/*******************************************************************************
* COMMON TO ALL PAGES
*/


/*****************************************************/
/* INDEX.PHP
/*****************************************************/


/*******************************************************************************
* X PAGE
*/

.pageX {
    section.header {

    }
}


/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/
@media screen and (max-width: $size-md-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}

@media screen and (max-width: $size-sm-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {

    }

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}


@media screen and (max-width: $size-xs-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {

    }

    /*******************************************************************************
    * X PAGE OVERRIDES
    */
}

/* Paste this css to your style sheet file or under head tag */
/* This only works with JavaScript, 
if it's not present, don't show loader */
.no-js #loader { display: none;  }
.js #loader { 
    // display: block; 
    position: absolute; 
    left: 100px; top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-white;
}
.se-pre-con {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background: center center no-repeat #fff;
    
    .loader {
        border: 16px solid #f3f3f3; /* Light grey */
        border-top: 16px solid $color-3; /* Blue */
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 1.2s linear infinite;
        
        border-top: 16px solid $color-3;
        border-bottom: 16px solid $color-3;
        border-right: 16px solid $color-1;        
        border-left: 16px solid $color-1;
    }
      
     @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

}
